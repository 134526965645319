"use strict";

const BackupAnalytics = require("./analytics/analyticIndex.js").BackupAnalytics;

if (!window.aCustomAnalyticsTrigger) {
    window.addEventListener("load", function () {
        const backupAnalytics = new BackupAnalytics();
        backupAnalytics.collect();
    });
}
